import * as ko from "knockout";
import {AttachmentDto} from "../../../api/generated";
import {config} from "../../../utils/clientConfigWrapper";


/**
 * Get documents from the attachments list.
 *
 * @param attachments
 * @param includeVideos
 */
export function attachmentDocuments(attachments: KnockoutComputed<AttachmentDto[]>, includeVideos: boolean = true): KnockoutComputed<AttachmentDto[]> {
    return ko.pureComputed(() =>
        attachments().filter(attachment =>
            attachment.image === false && (includeVideos || !attachment.filename.toLowerCase().endsWith('.mp4'))));
}

/**
 * Get images from the attachments list.
 *
 * @param attachments
 */
export function attachmentImages(attachments: KnockoutComputed<AttachmentDto[]>): KnockoutComputed<AttachmentDto[]> {
    return ko.pureComputed(() =>
        attachments()
            .filter(attachment => attachment.image === true)
            .sort((img1, img2) => img1.mainImage ? -1 : 0));
}

/**
 * Get images from the attachments list.
 *
 * @param attachments
 */
export function attachmentVideos(attachments: KnockoutComputed<AttachmentDto[]>): KnockoutComputed<AttachmentDto[]> {
    return ko.pureComputed(() =>
        attachments().filter(attachment => attachment.filename.toLowerCase().endsWith('.mp4')));
}

/**
 * Get the attachmant images styles.
 *
 * @param attachments
 */
export function attachmentImageStyles(attachments: KnockoutComputed<AttachmentDto[]>): KnockoutComputed<string[]> {
    return ko.pureComputed(() =>
        attachmentImages(attachments)().map(
            img => `background-image: url('${config.attachmentEndpoint}${img.filename}');`));
}

/**
 * Get image style.
 *
 * @param attachments
 */
export function attachmentImageStyle(attachment: AttachmentDto): KnockoutComputed<string> {
    return ko.pureComputed(() =>
        `background-image: url('${config.attachmentEndpoint}${attachment.filename}');`);
}
import * as ko from 'knockout'
import {AttachmentDto} from "../../../api/generated";
import {attachmentImageStyles} from "./attachmentUtils";

interface ViewModelParams {
    attachmentList: KnockoutComputed<AttachmentDto[]>;
    defaultImageStyle: string;
}

class ViewModel {

    /**
     * The complete attachment list.
     */
    public attachmentList: KnockoutComputed<AttachmentDto[]>;

    /**
     * The styles for the idea images. Image are rendered as background images.
     */
    public attachmentImageStyles: KnockoutComputed<String[]>;

    /**
     * Optional default image
     */
    private defaultImageStyle: string;

    /**
     * The id for this carousel.
     * The id of the first attachment or a random string
     */
    public carouselId: KnockoutComputed<String>;

    /**
     * Constructor.
     *
     * @param params
     */
    constructor(private params: ViewModelParams) {

        this.attachmentList = params.attachmentList;
        this.defaultImageStyle = params.defaultImageStyle ? params.defaultImageStyle : "";
        this.attachmentImageStyles = attachmentImageStyles(this.attachmentList);
        // add support for default image if there are no attachments
        // see IDW-37 Kategorien Bild in Detailansicht der Idee
        if(this.attachmentImageStyles().length < 1 && this.defaultImageStyle != "") {
            this.attachmentImageStyles = ko.pureComputed(() => Array(this.defaultImageStyle));
        }
        this.carouselId = ko.pureComputed(() => {
            const id = this.attachmentList().length > 0 ? this.attachmentList()[0].id.toString() : this.randomString();
            return "idea-image-carousel-" + id;
        });
    }

    /**
     * Get a random ID.
     * https://gist.github.com/6174/6062387
     */
    private randomString() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15).toString()
    }

}

const component: KnockoutComponentTypes.Config = {
    viewModel: (params: ViewModelParams) => new ViewModel(params),
    template: <string>require('./images-carousel.html')
};

export default component;

if (!ko.components.isRegistered('images-carousel')) {
    ko.components.register('images-carousel', component)
}